import { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import DatePickerControl from "../../../../../components/Controls/DatePickerControl";

import { URLS } from "../../../../../configs/api_urls";
import { httpPost } from "../../../../../utility/request";

import Highcharts from "highcharts";

var initialQuery = {
  assetId: 1,
  startDateTime: "2024-09-08T16:28:05.238Z",
  endDateTime: "2024-10-08T16:28:05.238Z",
};

const AssetAnalytics = ({assetId}) => {
  const [assetAnalyticsQuesry, setAssetAnalyticsQuesry] =
    useState({initialQuery});
  const [isLoading, setIsLoading] = useState(false);
  const [assetTrendAnalyticsData, setTrendAssetAnalyticsData] = useState(null);

  const fetchAssetTrendAnalytics = async () => {
    setIsLoading(true);
    const { data } = await httpPost(
      URLS.ASSET.ASSET_ANALYTICS.GET_ASSET_TREND_ANALYTICS,
      initialQuery
    );
    setTrendAssetAnalyticsData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAssetTrendAnalytics();

    Highcharts.chart("linecontainer", {
      title: {
        text: "U.S Solar Employment Growth",
        align: "left",
      },

      subtitle: {
        text: 'By Job Category. Source: <a href="https://irecusa.org/programs/solar-jobs-census/" target="_blank">IREC</a>.',
        align: "left",
      },

      yAxis: {
        title: {
          text: "Number of Employees",
        },
      },

      xAxis: {
        accessibility: {
          rangeDescription: "Range: 2010 to 2022",
        },
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: 2010,
        },
      },

      series: [
        {
          name: "Installation & Developers",
          data: [
            43934, 48656, 65165, 81827, 112143, 142383, 171533, 165174, 155157,
            161454, 154610, 168960, 171558,
          ],
        }
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });


    Highcharts.chart("barcontainer", {
      chart: {
        type: "column",
      },
      title: {
        text: "Corn vs wheat estimated production for 2023",
        align: "left",
      },
      subtitle: {
        text:
          'Source: <a target="_blank" ' +
          'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
        align: "left",
      },
      xAxis: {
        categories: ["USA", "China", "Brazil", "EU", "Argentina", "India"],
        crosshair: true,
        accessibility: {
          description: "Countries",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "1000 metric tons (MT)",
        },
      },
      tooltip: {
        valueSuffix: " (1000 MT)",
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Corn",
          data: [387749, 280000, 129000, 64300, 54000, 34300],
        },
      ],
    });
  }, []);

  return (
    <Box>
      <Box>
        <Typography fontWeight={600} className="field-title">
          Select Date Range
        </Typography>
        <Grid sx={{mt: 1, mb: 2}} container>
          <Grid item md={6}>
            <DatePickerControl
              id={"startDateTime"}
              name="startDateTime"
              label={"From"}
              value={initialQuery.startDateTime}
              onChange={
                (e) => {}
                //handleOfferExpiryDateChange(e, offer.assetOfferMapId)
              }
            ></DatePickerControl>
          </Grid>
          <Grid item md={6}>
            <DatePickerControl
              id={"endDateTime"}
              name="endDateTime"
              label={"To"}
              value={initialQuery.endDateTime}
              onChange={
                (e) => {}
                //handleOfferExpiryDateChange(e, offer.assetOfferMapId)
              }
            ></DatePickerControl>
          </Grid>
        </Grid>
      </Box>
      <div id="linecontainer"></div>
      <div id="barcontainer"></div>
    </Box>
  );
};

export default AssetAnalytics;
