import { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToggleButton, Typography, Box, Chip, Avatar } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { GameCenterViewContext } from "../../../../../utility/context";

import { URLS } from "../../../../../configs/api_urls";
import { httpGet, httpPatch } from "../../../../../utility/request";

import moment from "moment-timezone";
import AssetSlotListSkeleton from "../../../Skeletons/asset-slot-list-skeleton.component";
import ModalConfirmation from "../../../../../components/Modal/modal-confirmation.component";
import { addAlert } from "../../../../../modules/App/actions";

const SlotMointoring = ({ selectedAsset }) => {
  const dispatch = useDispatch();
  const [selectedAssetItem, setSelectedAssetItem] = useState(selectedAsset);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedAssetSlot, setselectedAssetSlot] = useState(null);

  const [isLoadingSlotList, setIsLoadingSlotList] = useState(false);
  const [isLoadingSlotBookings, setIsLoadingSlotBookings] = useState(false);
  const [isDisbleSlotPopupSlotVisible, setIsDisbleSlotPopupSlotVisible] =
    useState(false);;

  const [assetSlots, setAssetSlots] = useState([]);
  const [assetSlotBookings, setAssetSlotBookings] = useState([]);

  const gameCenterDetails = useContext(GameCenterViewContext);
  const futureActiveDays = gameCenterDetails.GameCenter.futureActiveDays;
  const themeColor = gameCenterDetails.GameCenter.applicationTheme;

  var today = new Date();
  var arr = [];

  for (var i = 0; i <= futureActiveDays; i++) {
    var nextDate = new Date(Date.now() + i * 24 * 60 * 60 * 1000);
    arr.push(nextDate);
  }

  function compare(dateTimeA, dateTimeB) {
    if (moment(dateTimeA).isSame(moment(dateTimeB), "D")) return true;
    else return false;
  }

  const fetchAssetSlots = async () => {
    setIsLoadingSlotList(true);
    const { data } = await httpGet(
      URLS.ASSET.GET_ASSET_SLOTS.replace(
        "#ASSETID#",
        selectedAssetItem.assetId
      ).replace("#SELECTEDDATE#", moment(selectedDate).utc().format())
    );
    setAssetSlots(data);
    setIsLoadingSlotList(false);
  };

  const fetchSlotBookings = async () => {
    if(selectedAssetSlot !== null) {
      setIsLoadingSlotBookings(true);
      const { data } = await httpGet(
        URLS.ASSET.GET_SLOT_BOOKINGS.replace("#ID#", selectedAssetSlot)
      );
      setAssetSlotBookings(data);
      setIsLoadingSlotBookings(false);
    }
  };

    const markSlotBooked = async () => {
      if (selectedAssetSlot !== null) {
        setIsLoadingSlotBookings(true);
        await httpPatch(
          URLS.ASSET.MARK_SLOT_BOOKED.replace("#ID#", selectedAssetSlot)
        ).then((data) => {
          dispatch(
            addAlert({
              type: "success",
              message: "Slot is marked Booked Successfully",
            })
          );
          setIsDisbleSlotPopupSlotVisible(false);
          fetchAssetSlots();
          setselectedAssetSlot(null)
        }).catch((error) => {
          dispatch(
            addAlert({
              type: "error",
              message: "Some error occuered!!",
            })
          );
        });
        setIsLoadingSlotBookings(false);
      }
    };

  useEffect(() => {
    setselectedAssetSlot(null);
    setIsLoadingSlotBookings(false);
    setAssetSlotBookings([]);
    fetchAssetSlots();
  }, [selectedDate]);

  useEffect(() => {
    fetchSlotBookings();
    setAssetSlotBookings([]);
  }, [selectedAssetSlot]);

  useEffect(() => {
    setAssetSlotBookings([]);
    setselectedAssetSlot(null)
    setIsLoadingSlotBookings(false)
  }, []);

  return (
    <Box>
      <ModalConfirmation
        heading={"Are you sure that you want to Disable this slot?"}
        subHeadhing={
          "Disabling slot action is irreversible. Mobile users will not be able to book this slot anymore"
        }
        isConfirmButton={true}
        confirmButtonText={"Yes"}
        onConfirmClick={() => markSlotBooked()}
        isCancelButton={true}
        cancelButtontext={"No"}
        onCancelClick={() => setIsDisbleSlotPopupSlotVisible(false)}
        open={isDisbleSlotPopupSlotVisible}
      />
      <Typography fontWeight={600} className="field-title">
        Select Date
      </Typography>
      <Box sx={{ display: "flex" }}>
        {arr.length > 0 &&
          arr.map((item, index) => (
            <Box key={index} sx={{ p: 1 }}>
              <ToggleButton
                value={item}
                selected={true}
                onClick={(event) => {
                  setSelectedDate(new Date(event.currentTarget.value));
                }}
                color={compare(item, selectedDate) ? "primary" : "standard"}
              >
                <Typography>{moment(item).format("DD, MMM")}</Typography>
              </ToggleButton>
            </Box>
          ))}
      </Box>
      <Typography fontWeight={600} className="field-title mt-20">
        Select Slot
      </Typography>
      {!isLoadingSlotList &&
        assetSlots.map((slot, index) => (
          <Box sx={{ display: "inline-flex" }}>
            <Chip
              variant="outlined"
              //disabled={slot.isDisabled || slot.isBooked}
              id={slot.assetTimeSlotsId}
              label={slot.slotDisplayText}
              value={slot.assetTimeSlotsId}
              style={{
                height: "40px",
                margin: "5px 2px",
                fontSize: "16px",
                background:
                  selectedAssetSlot == slot.assetTimeSlotsId
                    ? themeColor.backGroundColor
                    : slot.isDisabled || slot.isBooked
                    ? "lightgray"
                    : "",
              }}
              clickable={true}
              onClick={(e) => {
                setselectedAssetSlot(e.currentTarget.id);
              }}
              onDoubleClick={(e) => setIsDisbleSlotPopupSlotVisible(true)}
            />
            {slot.numberOfTimesBooked > 0 && (
              <div
                style={{
                  background: "green",
                  borderRadius: 13,
                  width: "25px",
                  height: "25px",
                  fontSize: "15px",
                  marginLeft: "-12px",
                  zIndex: "1",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    marginLeft: "10px",
                  }}
                >
                  {slot.numberOfTimesBooked}
                </span>
              </div>
            )}
          </Box>
        ))}
      <Box className="mt-20">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Booking#</TableCell>
                <TableCell>
                  <Typography>Booked by</Typography>
                </TableCell>
                <TableCell>Payment status</TableCell>
                <TableCell align="right">Payment Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assetSlotBookings.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.bookingId}
                  </TableCell>
                  <TableCell>
                    <Box className="display-flex">
                      <Avatar
                        src={
                          row.booking.user !== null
                            ? row.booking.user.profilePictureUrl
                            : ""
                        }
                        alt="Avatar"
                        style={{
                          borderRadius: "50%",
                          height: "50px",
                          width: "50px",
                        }}
                      ></Avatar>
                      <Typography className="ml-10 mt-10">
                        {row.booking.user.userName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {row.booking.phonepePaymentId === null ? "Pending" : "Done"}
                  </TableCell>
                  <TableCell align="right">{row.booking.totalBill}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoadingSlotBookings && <AssetSlotListSkeleton />}
        {!isLoadingSlotBookings && assetSlotBookings.length === 0 && (
          <Box className="m-auto">
            <Typography>No bookings</Typography>
          </Box>
        )}
      </Box>
      {isLoadingSlotList && <AssetSlotListSkeleton />}
    </Box>
  );
};

export default SlotMointoring;
