export const BASE_URL = "https://playnoot-api-prod.azurewebsites.net";

export const URLS = {
  ADVERTISEMENT: {
    GET_ALL_ADVERTISEMENT: `${BASE_URL}/Advertisement/GetAllAdvertisements`,
    CREATE_ADVERTISEMENT: `${BASE_URL}/Advertisement/CreateAdvertisement`,
    UPDATE_ADVERTISEMENT: `${BASE_URL}/Advertisement/UpdateAdvertisement`,
    DELETE_ADVERTISEMENT: `${BASE_URL}/Advertisement/DeleteAdvertisement`,
  },
  APPLICATION: {
    APPLICATION_INFO: `${BASE_URL}/Application/GetApplicationInfo`,
    APPLICATION_ADDRESSES: `${BASE_URL}/Application/GetApplicationAddresses`,
    GET_APPLICATIONS: `${BASE_URL}/Application/GetApplications`,
    SWITCH_APPLICATION: `${BASE_URL}/Application/SwitchApplication?applicationId=#ID#`,
    CREATE_APPLICATION: `${BASE_URL}/Application/CreateApplication`,
  },
  APPLICATION_INSTANCE: {
    SEARCH_APPLICATION_INSTANCE_BY_NAME: `${BASE_URL}/ApplicationInstance/SearchApplicationInstanceByName?applicationInstanceName=#NAME#`,
    GET_APP_INSTANCE_MOBILE: `${BASE_URL}/ApplicationInstance/GetApplicationInstanceListObjectMobile?applicationInstanceId=#ID#`,
  },
  ADMIN: {
    SIGN_IN: `${BASE_URL}/Accounts/AuthenticateUser`,
    GET_USER_DETAILS: `${BASE_URL}/Accounts/GetUserInfo`,
    SEND_RESET_PASSWORD_LINK: `${BASE_URL}/Accounts/SendResetPasswordLink`,
    SWITCH_APPLICATION: `${BASE_URL}/ApplicationInstance/SwitchApplicationInstance?applicationInstanceId=#ID#`,
    MARKCENTERLIVE: `${BASE_URL}/ApplicationInstance/MarkCenterLive?applicationInstanceId=#ID#`,
    MARKCENTEROFFLINE: `${BASE_URL}/ApplicationInstance/MarkCenterOffline?applicationInstanceId=#ID#`,
  },
  BANKING_DETAILS: {
    GET_ALL_SUBMITTED: `${BASE_URL}/BankingDetails/GetAllBankDetails`,
    VERIFY_BANK_DETAILS: `${BASE_URL}/BankingDetails/VerifyBankDetails?applicationAdminId=#ID#&statusType=#STATUS#`,
  },
  BOOKING: {
    GETBOOKINGDETAILSBYID: `${BASE_URL}/Booking/GetInstanceBookingById?bookingId=#ID#`,
  },
  BRANDING: {
    GET_ALL: `${BASE_URL}/Branding/GetBrandItems`,
    CREATE_BRAND: `${BASE_URL}/Branding/AddBrandingItem`,
    UPDATE_BRAND: `${BASE_URL}/Branding/UpdateBrandingItem`,
    DELETE_BRAND: `${BASE_URL}/Branding/DeleteBrandingItem?insatanceBrandingId=#ID#`,
  },
  CART: {
    ADD_TO_CART: `${BASE_URL}/AdminCart/AddToCart`,
    GET_CART_ITEMS: `${BASE_URL}/AdminCart/GetCartItems`,
    DELETE_CART_ITEM: `${BASE_URL}/AdminCart/RemoveFromCart?adminCartId=#ID#`,
    UPDATE_CART_ITEM: `${BASE_URL}/AdminCart/UpdateCartItem?adminCartId=#ADMIN_CART_ID#&pricingId=#PRICING_ID#&quantity=#QUANTITY#`,
  },
  COACHES: {
    GET_ALL: `${BASE_URL}/Coach/GetCoaches`,
    CREATE_COACH: `${BASE_URL}/Coach/CreateCoachUser`,
    UPDATE_COACH: `${BASE_URL}/Coach/UpdateCoachUser`,
    DELETE_COACH: `${BASE_URL}/Coach/DeleteCoach?coachId=#ID#`,
  },
  DASHBOARD: {
    GET_UPCOMING_GAME_SESSION: `${BASE_URL}/Dashboard/GetUpcomingGameSessions`,
    GET_BOOKING_STATISTICS_CHART_DATA: `${BASE_URL}/Dashboard/GetBookingStatisticsChartData`,
  },
  DEPARTMENT_USERS: {
    CREATE_USER: `${BASE_URL}/DepartmentUsers/CreateUser`,
  },
  FILES: {
    ADVERTISEMENT_IMAGE: `${BASE_URL}/Files/AdvertisementImages`,
    FACILITES_IMAGES: `${BASE_URL}/Files/FacilityImages`,
    PROFILE_IMAGE_UPLOAD: `${BASE_URL}/Files/ProfileImages`,
    EXTERNALPRODUCT_IMAGE_UPLOAD: `${BASE_URL}/Files/ExternalProductsImages`,
    PUSHNOTIFICATION_IMAGE_UPLOAD: `${BASE_URL}/Files/PushNotificationImages`,
    BRAND_IMAGE_UPLOAD: `${BASE_URL}/Files/AdvertisementImages`,
  },
  GAME_CENTERS: {
    CREATE: `${BASE_URL}/ApplicationInstance/CreateApplicationInstance`,
    LIST: `${BASE_URL}/ApplicationInstance/GetApplicationInstancesList`,
    GET_CENTER: `${BASE_URL}/ApplicationInstance/GetApplicationInstanceDetails`,
    GET_INSTANCE_STANDARD_GAMES: `${BASE_URL}/ApplicationInstance/GetApplicationInstanceStandardGamesByIdQuery?applicationInstanceId=#ID#`,
    GET_INSTANCE_BOOKINGS: `${BASE_URL}/Booking/GetInstanceBookings`,
  },
  MASTER: {
    GET_STANDARD_GAMES: `${BASE_URL}/Master/GetStandardGames`,
    GET_AVAILABLE_INSTANCE_THEME: `${BASE_URL}/Master/GetAvailableInstanceThemes`,
    GET_INSTANCE_TEMPLATES: `${BASE_URL}/Master/GetInstanceTemplates`,
    GET_AMENITIES: `${BASE_URL}/Master/GetAmenities`,
    GET_POLICY_TYPES: `${BASE_URL}/Master/GetAllPolicyTypes`,
  },
  ASSET: {
    CREATE_ASSET: `${BASE_URL}/Asset/CreateAsset`,
    UPDATE_ASSET: `${BASE_URL}/Asset/UpdateAsset`,
    UPDATE_ASSET_ACTIVE_STATUS: `${BASE_URL}/Asset/UpdateAssetActiveStatus?assetId=#ID#&status=#STATUS#`,
    UPDATE_ASSET_TYPE: `${BASE_URL}/AssetType/UpdateAssetType`,
    ASSET_TYPES_LIST: `${BASE_URL}/AssetType/GetAssetTypeList`,
    DELETE_ASSET_TYPES: `${BASE_URL}/AssetType/DeleteAssetType?assetTypeId=#ID#`,
    CREATE_ASSET_TYPES: `${BASE_URL}/AssetType/CreateAssetType`,
    GETASSETOFFERS: `${BASE_URL}/AssetOffers/GetOffersList`,
    ASSET_LIST_BY_INSTANCE: `${BASE_URL}/Asset/GetAssets?applicationInstanceId=#ID#`,
    GET_ASSET_LIST: `${BASE_URL}/Asset/GetAssetListVM`,
    GET_ASSET_SLOTS: `${BASE_URL}/Asset/GetAssetSlotList?assetId=#ASSETID#&selectedDate=#SELECTEDDATE#`,
    GET_ASSET_DETAILS: `${BASE_URL}/Asset/GetAssetVMById?assetId=#ID#`,
    GET_ASSET_LINKED_OFFER_LIST: `${BASE_URL}/AssetOffers/GetAssetLinkedOfferList?assetOfferId=#ID#`,
    UPDATE_ASSET_OFFER: `${BASE_URL}/AssetOffers/UpdateAssetOffer`,
    CREATE_ASSET_OFFER: `${BASE_URL}/AssetOffers/CreateAssetOffer`,
    DELETE_ASSET_OFFER: `${BASE_URL}/AssetOffers/ArchiveAssetOffer?assetOfferId=#ID#`,
    GET_SLOT_BOOKINGS: `${BASE_URL}/Asset/GetSlotBookings?slotId=#ID#`,
    MARK_SLOT_BOOKED: `${BASE_URL}/Asset/MarkSlotBooked?slotId=#ID#`,
    ASSET_ANALYTICS: {
      GET_ASSET_TREND_ANALYTICS: `${BASE_URL}/AssetAnalytics/GetAssetTrendAnalytics`,
      GET_ASSET_PERFORMENCE_DISTRIBUTION: `${BASE_URL}/AssetAnalytics/GetAssetsPerformenceDistribution`,
    },
  },
  External_PRODUCTS: {
    CREATE: `${BASE_URL}/ExternalProducts/CreateExternalProducts`,
    DELETE: `${BASE_URL}/ExternalProducts/ArchiveExternalProduct?externalProductId=#ID#`,
    UPDATE: `${BASE_URL}/ExternalProducts/UpdateExternalProduct`,
    GET_ALL: `${BASE_URL}/ExternalProducts/GetExternalProducts`,
  },
  LICENSE: {
    GET_ALL: `${BASE_URL}/AdminLicense/GetLicensePlans`,
    GET_APPLICATION_LICENSE: `${BASE_URL}/AdminLicense/GetApplicationLicense`,
    GET_APPLICATION_LICENSE_CONSUMPTION: `${BASE_URL}/AdminLicense/GetApplicationLicenseConsumption`,
  },
  CCAVENUE: {
    INITIATETRANSECTION:
      "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction",
  },
  CHECKOUT: {
    PAYMENTTOKEN: `${BASE_URL}/Payment/Checkout`,
    GETPAYMENTBYID: `${BASE_URL}/Payment/GetPaymentByOrderId?orderId=#ID#`,
    GETPAYMENTRECEIPT: `${BASE_URL}/Payment/GenerateReceipt?orderId=#ID#`,
  },
  INSTAMOJOPAYMENT: {
    PAYMENTREQUEST: `${BASE_URL}/Payment/CreateInstaMojoPaymentRequest`,
    GETINSTAMOJOPAYMENTS: `${BASE_URL}/Payment/GetAdminInstaMojoPayments`,
  },
  ANNOUNCEMENTS: {
    BROADCASTMESAGE: `${BASE_URL}/Announcement/InstanceBraodcastMessage`,
    GET_SENT_NOTIFICATIONS: `${BASE_URL}/Notification/GetSentNotifications`,
    DELETE_ADMIN_NOTIFICATION: `${BASE_URL}/Notification/DeleteAdminNotificationLog?notificationIdentifierId=#ID#`,
    SUPER_ADMIN_ANNOUNCEMENT: `${BASE_URL}/PushNotificationSuperAdmin/SentSuperAdminAnnouncementPush`,
  },
  PHONEPEPAYMENT: {
    PAYMENTREQUEST: `${BASE_URL}/Payment/CreatePhonepePaymentRequest`,
    GETPHONEPEPAYMENTS: `${BASE_URL}/Payment/GetAdminPhonepePayments`,
    GETPHONEPEPAYMENTDETAILS: `${BASE_URL}/Payment/GetPhonepePaymentDetails`,
    ADD_OFFLINE_PAYMENT: `${BASE_URL}/Payment/AddOfflinePayment`,
    DELETE_OFFLINE_PAYMENT: `${BASE_URL}/Payment/DeleteOfflinePayment?phonepePaymentId=#ID#`,
  },
  PAYMENT_SETTLEMENTS: {
    GET_PAYMENT_SETTLEMENTS: `${BASE_URL}/PaymentSettlement/GetPaymentSettlements`,
    GET_PAYMENT_SETTLEMENT_BY_ID: `${BASE_URL}/PaymentSettlement/GetPaymentSettlementById?paymentSettlementId=#ID#`,
  },
  ADMIN_ORDERS: {
    GETADMINORDERS: `${BASE_URL}/AdminOrders/GetAdminOrders`,
    GETADMINORDERDETAILS: `${BASE_URL}/AdminOrders/GetOrderByAdminOrderId?adminOrderId=#ID#`,
  },
  ADMIN_USERS: {
    GETDEPARTMENTADMINS: `${BASE_URL}/DepartmentUsers/GetDepartmentAdmins`,
    CREATEDEPARTMENTUSER: `${BASE_URL}/DepartmentUsers/CreateUser`,
  },
  ADMIN_BANKING: {
    GETALLBANKINGDETAILS: `${BASE_URL}/AdminBanking/GetAllAdminBankDetails`,
    ADDBANKDETAILS: `${BASE_URL}/AdminBanking/AddBankDetails`,
    UPDATEBANKDETAILS: `${BASE_URL}/AdminBanking/UpdateBankDetails`,
  },
  NOTIFICATIONS: {
    GETALLNOTIFICATIONS: `${BASE_URL}/Notification/GetUserNotifications`,
    GETNOTIFICATIONCOUNT: `${BASE_URL}/Notification/GetUnreadNotificationCount`,
    MARKUSERNOTIFICATIONREAD: `${BASE_URL}/Notification/MarkUserNotificationsRead`,
    DELETEUSERNOTIFICATION: `${BASE_URL}/Notification/DeleteUserNotification?notificationLogId=#ID#`,
  },
  ONLINE_LEADS: {
    GET_ALL_LEADS: `${BASE_URL}/OnlineLead/GetAllLeads`,
    DELETEL_LEAD: `${BASE_URL}/OnlineLead/DeleteLead?leadId=#ID#`,
  },
  PLATFORM_CHARGES: {
    GET_PLATFORM_CHARGES: `${BASE_URL}/ApplicationPlatformCharges/GetPlatformCharges`,
    ADD_CHARGES: `${BASE_URL}/ApplicationPlatformCharges/AddApplicationPlatformCharges`,
    UPDATE_CHARGES: `${BASE_URL}/ApplicationPlatformCharges/UpdateApplicationPlatformCharges`,
    DELETE_CHARGES: `${BASE_URL}/ApplicationPlatformCharges/DeleteApplicationPlatformCharges?platformChargeId=#ID#`,
  },
  PLAYERS: {
    GETINSTANCEPLAYERS: `${BASE_URL}/Player/GetInsatncePlayers`,
    ADDPLAYER: `${BASE_URL}/Player/AddPlayer?userId=#ID#`,
    REMOVEPLAYER: `${BASE_URL}/Player/RemovePlayer?playerId=#ID#`,
    SAVEPLAYERNOTE: `${BASE_URL}/Player/SavePlayerNote?playerId=#ID#&noteText=#NOTETEXT#`,
    GETPLAYERBYID: `${BASE_URL}/Player/GetPlayerById?playerId=#ID#`,
  },
  PLAYER_CREDITS: {
    GETPLAYERCREDITS: `${BASE_URL}/Credits/GetPlayerCredits?playerId=#ID#`,
    ADDPLAYERCREDIT: `${BASE_URL}/Credits/AddPlayerCredit`,
    EDITPLAYERCREDIT: `${BASE_URL}/Credits/EditPlayerCredit`,
    REMOVEPLAYERCREDIT: `${BASE_URL}/Credits/RemovePlayerCredit?creditId=#ID#`,
  },
  SUBSCRIPTION_PLANS: {
    GETSUBSCRIPTIONPLANSLISTVM: `${BASE_URL}/InstanceSubscriptionPlans/GetSubscriptionPlansListVM`,
    GETSUBSCRIPTIONPLANBYID: `${BASE_URL}/InstanceSubscriptionPlans/GetSubscriptionPlanById?planId=#ID#`,
    UPDATESUBSCRIPTIONPLAN: `${BASE_URL}/InstanceSubscriptionPlans/UpdateSubscriptionPlan`,
    CREATESUBSCRIPTIONPLAN: `${BASE_URL}/InstanceSubscriptionPlans/CreateSubscriptionPlan`,
    ARCHIVESUBSCRIPTIONPLAN: `${BASE_URL}/InstanceSubscriptionPlans/ArchiveSubscriptionPlan?planId=#ID#`,
  },
  SUBSCRIPTION_PLAN_OFFERS: {
    GETSUBSCRIPTIONPLANOFFERS: `${BASE_URL}/InstanceSubscriptionPlans/GetSubscriptionPlanOffers`,
    GETSUBSCRIPTIONPLANOFFERLINKEDPLANS: `${BASE_URL}/InstanceSubscriptionPlans/GetSubscriptionOfferLinkedPlans?offerId=#ID#`,
    UPDATESUBSCRIPTIONPLANOFFER: `${BASE_URL}/InstanceSubscriptionPlans/UpdateSubscriptionPlanOffer`,
    CREATESUBSCRIPTIONPLANOFFER: `${BASE_URL}/InstanceSubscriptionPlans/CreateSubscriptionPlanOffer`,
    ARCHIVESUBSCRIPTIONPLANOFFER: `${BASE_URL}/InstanceSubscriptionPlans/ArchiveSubscriptionPlanOffer?subOfferId=#ID#`,
  },
  SUBSCRIPTION_PURCHASE: {
    GETINSTANCESUBSCRIPTION: `${BASE_URL}/AdminSubscriptiionPlanPurchase/GetApplicationInstanceSubscriptions`,
    GETSUBSCRIPTIONORDERBYID: `${BASE_URL}/AdminSubscriptiionPlanPurchase/GetInstanceUserSubscriptionById?subscriptionPlanUserOrderId=#ID#`,
  },
  SUPER_ADMIN: {
    BOOKINGS: {
      GET_ALL_BOOKINGS: `${BASE_URL}/Booking/GetAllBookings`,
    },
    PAYMENTS: {
      GET_PHONEPE_PAYMENTS: `${BASE_URL}/Payment/GetPhonepePayments`,
    },
    APPLICATION: {
      GET_APPLICATIONS: `${BASE_URL}/Application/GetApplications`,
    },
    APPLICATION_INSTANCE: {
      GET_INSTANCES_BY_APP_ID: `${BASE_URL}/ApplicationInstance/GetApplicationInstanceListByAppId?applicationId=#ID#`,
    },
    LICENSE: {
      CREATE_LICENSE: `${BASE_URL}/License/CreateLicensePlan`,
      UPDATE_LICENSE: `${BASE_URL}/License/UpdateLicensePlan`,
      GET_LICENSE_PLANS: `${BASE_URL}/License/GetLicensePlans`,
      DELETE_LICENSE: `${BASE_URL}/License/DeleteLicensePlan?planId=#ID#`,
      SET_LICENSE_DEFAULT: `${BASE_URL}/License/SetLicensePlanDefault?planId=#ID#`,
    },
  },
  RATING_AND_REVIEWS: {
    GET_APPLICATIONINSTANCE_REVIEWS: `${BASE_URL}/RatingAndReview/GetApplicationInstanceReviews`,
    GET_APPLICATIONINSTANCE_RATINGREVIEWS_DETAILS: `${BASE_URL}/RatingAndReview/GetApplicationInstanceRatingReviewDetails?applicationInstanceId=#ID#`,
    GET_INSTANCE_FOLLOWERS: `${BASE_URL}/RatingAndReview/GetInstanceFollowers`,
  },
  TASKS: {
    CREATE_TASK: `${BASE_URL}/Task/CreateTask`,
    UPDATE_TASK: `${BASE_URL}/Task/UpdateTask`,
    GET_ALL_TASKS: `${BASE_URL}/Task/GetAllTasks`,
    MARK_TASK_COMPLETED: `${BASE_URL}/Task/MarkTaskCompleted?taskId=#ID#`,
    DELETE_TASK: `${BASE_URL}/Task/DeleteTask?taskId=#ID#`,
  },
  USER: {
    SEARCH_USER: `${BASE_URL}/Accounts/SearchUserByName?userName=#NAME#&pageNumber=#PAGENUMBER#&pageSize=#PAGESIZE#`,
    SEARCH_AVAILABLE_PLAYERS: `${BASE_URL}/Accounts/SearchAvailablePlayersByName?userName=#NAME#&pageNumber=#PAGENUMBER#&pageSize=#PAGESIZE#`,
    UPDATE_PROFILE_PICTURE: `${BASE_URL}/Accounts/UpdateProfilePicture?newProfilePictureUrl=#PICTUREURL#`,
    UPDATE_BASIC_DETAILS: `${BASE_URL}/Accounts/UpdateBasicDetails`,
  },
  APPLICATION_INSTANCE_SETTINGS: {
    ADD_INSTANCE_PICTURE: `${BASE_URL}/ApplicationInsatnceSettings/AddInsatncePicture`,
    ADD_INSTANCE_AMENITY: `${BASE_URL}/ApplicationInsatnceSettings/AddInstanceAmenity?aminityId=#ID#`,
    ADD_STANDARD_GAME: `${BASE_URL}/ApplicationInsatnceSettings/AddStandardGame?stnadardGameId=#ID#`,
    UPDATE_CENTER_DETAILS: `${BASE_URL}/ApplicationInsatnceSettings/UpdateCenterDetails`,
    UPDATE_CONTACT_DETAILS: `${BASE_URL}/ApplicationInsatnceSettings/UpdateInstanceContactDetails`,
    UPDATE_ADDRESS: `${BASE_URL}/ApplicationInsatnceSettings/UpdateAdress`,
    UPDATE_INSTANCE_THEME: `${BASE_URL}/ApplicationInsatnceSettings/UpdateInstanceTheme?themeId=#ID#`,
    UPDATE_INSTANCE_POLICY: `${BASE_URL}/ApplicationInsatnceSettings/UpdateApplicationInstanceCenterPolicies`,
    DELETE_INSTANCE_PICTURE: `${BASE_URL}/ApplicationInsatnceSettings/DeleteInsatncePicture?instancePictureId=#ID#`,
    DELETE_STANDARD_GAME: `${BASE_URL}/ApplicationInsatnceSettings/DeleteStandardGame?standardgameMapId=#ID#`,
    DELETE_INSTANCE_AMENITY: `${BASE_URL}/ApplicationInsatnceSettings/DeleteInstanceAmenity?instanceAmenityMapId=#ID#`,
  },
  GAME_SESSIONS: {
    GET_ACTIVE_GAME_SESSIONS: `${BASE_URL}/GameSession/GetActiveGameSessions`,
    CREATE_GAME_SESSION: `${BASE_URL}/GameSession/CreateGameSession`,
    PAUSE_RESUME_GAME_SESSION: `${BASE_URL}/GameSession/PauseResumeGameSession?gameSessionId=#GAME_SESSIONID#&elapsedTime=#ELAPSED_TIME#&flag=#FLAG#&startTime=#START_TIME#`,
    STOP_GAME_SESSION: `${BASE_URL}/GameSession/StopGameSession?gameSessionId=#GAME_SESSIONID#`,
  },
};
